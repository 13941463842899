<template>
  <section v-if="ready">
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      icon="fa fa-dashboard"
    />
    <div
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby=""
      v-if="items && isEmpty && !busy"
    >
      <EmptyListAlert
        buttonLabel="new_screen"
        importLabel="import"
        newItemPath=""
        @new="create()"
        @import="modal = 'import'"
      />
    </div>
    <div v-else-if="items" class="tab-pane active" id="edit" role="tabpanel">
      <ScreenSearchTable
        ref="screen_search_table"
        class="result-table"
        :items="items"
        :pagination="pagination"
        :multiSelection="multiSelection"
        @loading="$emit('loading', $event)"
        @select="onSelect"
        @command="onCommand"
        @multiColumnSort="onMultiColumnSort"
        @clearSelection="clearSelection"
        @updateItem="updateItem"
        :key="cid"
      >
      </ScreenSearchTable>
    </div>
    <div class="tab-pane" id="list" role="tabpanel" aria-labelledby=""></div>
    <PublishForm
      v-if="modal == 'publish'"
      @close="onClosePublishDialog()"
      @saved="onPublished($event)"
      :screenId="screen.id || ''"
      :isNew="screen.id < 0"
    />
    <SaveAsForm
      v-else-if="modal == 'saveas'"
      @close="onCloseSaveAsDialog($event)"
      :screenId="screen.id || ''"
    />
    <ImportForm
      v-else-if="modal == 'import'"
      @close="onCloseImportDialog($event)"
    />
    <DashboardDownloadBtn
      v-else-if="modal == 'download' && screen"
      :screenId="screen && screen.id"
      :auto="true"
    />
  </section>
</template>
<script>
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import ScreenService from "@/services/screen.js";
import ContractService from "@/services/contract.js";
import PublishForm from "@/components/editor/publish-form.vue";
import SaveAsForm from "@/components/editor/save-as-form.vue";
import ImportForm from "@/components/editor/import-form.vue";
import ScreenSearchTable from "@/components/editor/screen-search-table.vue";
import DashboardDownloadBtn from "@/components/editor/dashboard-download-btn.vue";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";

export default {
  name: "DashboardEditScreenPicker",
  extends: DashboardEditPickerBase,
  components: {
    PublishForm,
    SaveAsForm,
    ImportForm,
    DashboardDownloadBtn,
    ScreenSearchTable,
    EmptyListAlert
  },
  data: () => ({
    ready: false,
    entityType: "screen",
    itemFilter: {},
    screen: null,
    modal: null,
    restoring: null,
    cid: 0
  }),
  computed: {
    screenList() {
      return (this.$store.getters["dashboard/screens"] || []).filter(
        (item) => parseInt(item.id) < 1000000000 && !item.public
      );
    }
  },
  watch: {
    screenList: {
      handler(n, o) {
        let result;
        if (n && !o) this.ready = true;
        if (n) {
          if (n.length) {
            try {
              result = structuredClone(n);
            } catch (error) {
              result = JSON.parse(JSON.stringify(n));
            }
            if (result && !this.items) {
              this.onResposeReady(result);
              this.$emit("loading", false);
            }
          } else {
            this.items = null;
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    create(command) {
      let params = command?.selectedNode
        ? `?parent=${command?.selectedNode}`
        : "";
      this.$router.push(`/dashboard/screen/edit/new${params}`);
      return;
    },
    edit(command) {
      this.$router.push("/dashboard/screen/edit/" + command.target.id);
    },
    clone(command) {
      this.clonningId = command.target.id;
      this.$store
        .dispatch("dashboard/initDraft", command.target.id)
        .then((template) => {
          if (template) {
            this.screen = command.target;
            this.modal = "saveas";
          }
        });
    },
    restore(command) {
      if (!command.target) return;
      if (command.target.id > 0) {
        this.$set(this, "restoring", command.target);
        this.$store
          .dispatch("dashboard/restoreDraft", command.target.id)
          .then(() => {
            this.$router.push("/dashboard/screen/edit/" + command.target.id);
          });
      }
    },
    publish(command) {
      this._publishingId = command?.target?.id ?? null;
      this.$store
        .dispatch("dashboard/initDraft", command.target.id)
        .then((template) => {
          if (template) {
            this.screen = command.target;
            this.modal = "publish";
          }
        });
    },
    import() {
      this.modal = "import";
    },
    download(command) {
      this.$store
        .dispatch("dashboard/initDraft", command.target.id)
        .then((template) => {
          if (template) {
            this.screen = null;
            this.modal = null;

            this.$nextTick(() => {
              this.screen = command.target;
              this.modal = "download";
            });
          }
        });
    },
    onPublished(screen) {
      if (
        this._publishingId &&
        screen &&
        screen.id &&
        parseInt(this._publishingId) != parseInt(screen.id)
      ) {
        this.$root.$emit("entity:id-changed", {
          type: "screen",
          from: this._publishingId,
          to: screen.id
        });
        this._publishingId = null;
      }
      this.onCloseModal(screen && screen?.id);
    },
    onClosePublishDialog() {
      this.modal = null;
      this.screen = null;
      // this._publishingId = null;
    },
    onCloseSaveAsDialog(screenId) {
      let nodeId =
        screenId && this.clonningId
          ? (this?.$refs?.screen_search_table?.tree.leaves || {})[
              this.clonningId
            ]
          : null;
      if (nodeId) {
        this.$store.dispatch("dashboard/setParent", {
          dbKey: "tree_screen",
          id: screenId,
          parentId: nodeId == "trash_can" ? "root" : nodeId
        });
      }
      this.clonningId = null;
      this.onCloseModal(screenId);
    },
    onCloseImportDialog(screenId) {
      this.onCloseModal(screenId);
      this.cid += 1;
    },
    removalMessage(item) {
      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          this.entityType,
          this.$tc("n_records_selected", item.length, {
            amount: item.length || 1
          }),
          "you_wont_be_able_to_revert_this"
        );
      }
      return "";
    },
    remove(command) {
      let config = {
        type: this.entityType,
        rule: "TelaCustomizadaEscrita"
      };
      if (command.target) {
        if (command.target.id) {
          if (command.target.public) return;
          config.item = {
            id: command.target.id,
            etag: command.target.etag,
            name: this.screenName(command.target)
          };
        } else if (command.target.length) {
          config.items = command.target;
        }
      }
      let lst = config.items ? config.items : [config.item.id];
      if (!lst.length) return;
      // perform removal tasks
      const _execute = (lst) => {
        const _removeItem = (screen) => {
          return new Promise((resolve) => {
            if (screen.public) {
              resolve();
              return;
            }
            let contract_portal_data_payload = null;
            // not published, just discharge it
            if (parseInt(screen.id) <= 0) {
              this.$store.dispatch("dashboard/remove", screen.id).then(() => {
                this.updateItems();
                this.$set(this.multiSelection, "values", []);
              });
              resolve();
              return;
            } else {
              if (this?.contract?.portal_data?.search_page) {
                let search_page = {};
                let check = [0, 0];
                for (var p in this.contract.portal_data.search_page) {
                  check[0] += 1;
                  if (
                    this.contract.portal_data.search_page[p].screen_id !=
                    screen.id
                  ) {
                    check[1] += 1;
                    search_page[p] = JSON.parse(
                      JSON.stringify(this.contract.portal_data.search_page[p])
                    );
                  }
                }
                if (check[0] != check[1]) {
                  contract_portal_data_payload =
                    this.contract.portal_data || {};
                  contract_portal_data_payload.search_page = search_page;
                }
              }
            }
            let req = null;
            var payload = {...screen};
            if (payload.deleted_at) {
              payload.portal_data = screen.portal_data || {};
              payload.portal_data.excluded_at = new Date().toISOString();
              req = this.service.save;
            } else {
              payload.portal_data = screen.portal_data || {};
              payload.portal_data.excluded_at = null;
              payload.deleted_at = new Date().toISOString();
              req = this.service.save;
            }
            req(payload).then((response) => {
              if (response) {
                if (contract_portal_data_payload) {
                  this.updateContractPortalData(contract_portal_data_payload);
                }
                this.$store
                  .dispatch("dashboard/setScreen", response)
                  .then(() => {
                    this.updateItems();
                    this.$set(this.multiSelection, "values", []);
                    resolve();
                  });

                // Desacopla/remove as referências a esta Tela
                (this.$store.getters["dashboard/connectorList"] || [])
                  .filter(({screen_id}) => screen_id == payload.id)
                  .forEach((item) => {
                    item.screen_id = null;
                    this.$store.dispatch("dashboard/setConnectorValue", [item]);
                  });
                (this.$store.getters["dashboard/deviceList"] || [])
                  .filter(({screen_id}) => screen_id == payload.id)
                  .forEach((item) => {
                    item.screen_id = null;
                    this.$store.dispatch("dashboard/setDeviceValue", [item]);
                  });
              } else {
                resolve();
              }
            });
          });
        };
        let promises = lst
          .map((screenId) => {
            let screen = this.items.find(
              ({id, portal_data}) =>
                parseInt(id) == parseInt(screenId) &&
                (!portal_data || !portal_data.excluded_at)
            );
            return screen ? _removeItem(screen) : null;
          })
          .filter((i) => i !== null);
        //====================
        this.$emit("loading", true);
        Promise.all(promises).then(() => {
          this.$emit("loading", false);
        });
      };

      if (
        lst.some((screenId) =>
          this.items.some(
            ({id, deleted_at}) =>
              parseInt(screenId) == parseInt(id) && deleted_at
          )
        )
      ) {
        this.confirmRemoval(config).then((confirmed) => {
          if (!confirmed) return;
          _execute(lst);
        });
      } else {
        _execute(lst);
      }
    },
    updateItem(screenId) {
      const ix = (this.items || []).findIndex(
        ({id}) => parseInt(id) == parseInt(screenId)
      );
      if (ix >= 0) {
        this.$set(
          this.items,
          ix,
          this.screenList.find(({id}) => parseInt(id) == parseInt(screenId))
        );
        this.$set(
          this.multiSelection,
          "values",
          this.multiSelection.values.filter(
            (id) => parseInt(id) != parseInt(screenId)
          )
        );
      }
    },
    updateItems() {
      let items = this.screenList;
      this.items = items.sort((a, b) => {
        var ax = `${a.public ? 1 : 0}${a.name.toUpperCase()}`;
        var bx = `${b.public ? 1 : 0}${b.name.toUpperCase()}`;
        return ax > bx ? 1 : bx > ax ? -1 : 0;
      });
      this.maxResult = this.items.length;
    },
    fetchItems() {
      this.$store
        .dispatch("dashboard/fetchScreens", {contract_id: this.contract.id})
        .then(() => {
          this.updateItems();
          this.$emit("loading", false);
          this.busy = false;
        });
    },
    updateContractPortalData(portal_data) {
      let payload = {
        portal_data: JSON.parse(JSON.stringify(portal_data)),
        etag: this?.contract?.etag || "",
        id: this?.contract?.id || ""
      };
      if (!payload.id || !payload.etag) return null;
      if (!payload) return;
      this.savePortalData(payload);
    },
    savePortalData(payload) {
      new ContractService().updateContract(payload).then((response) => {
        if (response && typeof response == "object" && response.id) {
          this.$store.dispatch("user/configureUserContract");
        } else {
          this.$swal({
            title: this.$t("item_could_not_be_saved"),
            text: this.$t("invalid_data"),
            icon: "error"
          });
        }
      });
    },
    onCloseModal(screenId) {
      // screenId might be different from the current one
      this.updateItems();
      this.modal = null;
      if (this.screen && screenId && screenId != this.screen.id) {
        this.$store.commit("dashboard/RESET_DRAFT");
        this.$store.dispatch("dashboard/initDraft", screenId);
        this.$nextTick(() => {
          this.$router.push(`/dashboard/screen/edit/${screenId}`);
        });
      }
      this.screen = null;
    },
    screenName(screen) {
      let name = screen.name;
      if (screen.id < 0) {
        name = this.$t("new_screen");
        name = `${name} (${
          -1 * screen.id <= 9 ? "0" + -1 * screen.id : -1 * screen.id
        })`;
      }
      return name;
    }
  },
  beforeCreate() {
    this.service = new ScreenService();
  },
  created() {
    this.title = "screen_list";
    this.multiSelection.key = "id";
    // this.fetchItems();
    
    if (!(this.$store.getters["synoptic/imagesLibraries"] || []).length) {
      this.$store.dispatch("synoptic/fetchImagesLibraries");
      this.$store.dispatch("synoptic/fetchImages");
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.ready && !this.items) {
        this.onResposeReady([]);
        this.$emit("loading", false);
      }
    }, 1000);
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 5px 0 30px 0;
  padding: 10px 0 0 0;
}

.result-table {
  /* min-height: 400px; */
  /* padding: 0 10px 100px 10px; */
}

.customFilters {
  margin-top: 10px;
}

.form-group {
  margin-bottom: 0;
}

.filter-option {
  margin-right: 15px;
}

.filter-option span {
  vertical-align: top;
  margin-left: 5px;
}

.filter-option:hover {
  cursor: pointer;
  color: #72afd2;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
